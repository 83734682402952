import React from "react";
import { observer, useLocalObservable } from "mobx-react";
import { TableRow, TableCell } from "@mui/material";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import DataTableStore from "wes_shell_app/data-table-store";
import { environment } from "environment";
import BaseTableViewStore from "wes_shell_app/base-table-view-store";
import {
  IServerTransportsModel,
  IWebsocketsTransportsByQueueModel,
} from "models/server-models";
import { IClientTransportsModel } from "models/client-models";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "queue",
  },
  {
    label: "count",
  },
];

interface ITotalTransportsDataTableProps {
  rows: IWebsocketsTransportsByQueueModel[];
}

export const TransportsDataTable = observer(
  (props: ITotalTransportsDataTableProps) => {
    const state = useLocalObservable(() => ({
      store: new ViewStore(),
    }));

    return (
      <DataTable
        hidePaging
        isLoading={!state.store.isLoaded}
        rows={props.rows}
        columns={columns}
        rowTemplate={(item) => <RowTemplate key={item.id} item={item} />}
      />
    );
  }
);

type IRowTemplateProps = {
  item: IWebsocketsTransportsByQueueModel;
};

const RowTemplate = ({ item }: IRowTemplateProps) => (
  <TableRow>
    <TableCell>{item.queue}</TableCell>
    <TableCell>{item.count}</TableCell>
  </TableRow>
);

class ViewStore extends BaseTableViewStore<
  IServerTransportsModel,
  IClientTransportsModel,
  null,
  Provider
> {
  constructor() {
    super(new Provider());
  }
}

class Provider extends DataTableStore<IServerTransportsModel, null> {
  get endpointUrl(): string {
    return "api/users";
  }
  protected serviceApiUrl = environment.structureServiceApi;
}
