import { environment } from 'environment';
import DataTableStore from 'wes_shell_app/data-table-store';
import SharedContextStore from 'wes_shell_app/shared-context-store';
import { IAmrTransportFilterParams } from '../../utils/amr-transport-utils';

export class AmrTransportDataTableProvider extends DataTableStore<any, IAmrTransportFilterParams> {
  readonly sharedContextStore = new SharedContextStore();

  get endpointUrl(): string { return 'ui/transports'; }
  protected serviceApiUrl = environment.amrServiceApi;

  get defaultTimeFromValue() {
    let timeFrom = new Date();
    timeFrom.setHours(timeFrom.getHours() - 1);
    return timeFrom;
  }

  get defaultFilter() {
    const store = new SharedContextStore();
    return new Map<any, any>([
      //stationId is required
      ['stationId', store.appContext.currentStationId],
      //timeFrom is required
      ['timeFrom', this.defaultTimeFromValue.toISOString()]
    ]);
  }

  get filterDependencies(): Map<IAmrTransportFilterParams, IAmrTransportFilterParams[]> {
    return new Map<IAmrTransportFilterParams, IAmrTransportFilterParams[]>([
      ['sourceWorkplace', ['sourcePosition']],
      ['targetWorkplace', ['targetPosition']],
    ]);
  }
}
