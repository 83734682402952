import { observer } from "mobx-react";
import React from "react";
import BaseView from "wes_shell_app/base-view";
import Big from "wes_shell_app/big";
import { ProcessDataTable } from "../components/process-data-table";
import { TechnologyDataTable } from "../components/technology-data-table";
import { FatalDataTable } from "../components/fatal-data-table";
import { DashboardHeader } from "../components/dashboard-header";
import { ActiveRobotDataTable } from "../components/active-robot-data-table";
import { TaskControl } from "../components/task-control";
import { SimpleCard } from "wes_shell_app/simpleCard";

export const DashboardView = observer(() => {
  return (
    <BaseView cardBackgroundColor={false}>
      <DashboardHeader />
      <FatalDataTable />
      <div className="flex">
        <div className="w-full max-w-[80%]">
          <SimpleCard>
            <Big intlId="processTableMessagesLabel" />
            <ProcessDataTable />
          </SimpleCard>
          <SimpleCard>
            <Big intlId="technologyTableLabel" />
            <TechnologyDataTable />
          </SimpleCard>
        </div>
        <div className="pl-2 w-full max-w-[20%]">
          <SimpleCard>
            <Big intlId="allocationRequirements" />
            <TaskControl />
          </SimpleCard>
          <SimpleCard>
            <Big intlId="activeRobotDataTable" />
            <ActiveRobotDataTable />
          </SimpleCard>
        </div>
      </div>
    </BaseView>
  );
});
