import React from "react";
import { observer, useLocalObservable } from "mobx-react";
import { TableRow, TableCell } from "@mui/material";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import DataTableStore from "wes_shell_app/data-table-store";
import { environment } from "environment";
import BaseTableViewStore from "wes_shell_app/base-table-view-store";
import {
  IServerTransportsModel,
  IWebsocketBufferModel,
} from "models/server-models";
import { IClientTransportsModel } from "models/client-models";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "name",
  },
  {
    label: "occupancy",
  },
  {
    label: "queue",
  },
  {
    label: "content",
  },
];

interface IBuffersDataTableProps {
  rows: IWebsocketBufferModel[];
}

export const BuffersDataTable = observer(
  (props: IBuffersDataTableProps) => {
    const state = useLocalObservable(() => ({
      store: new ViewStore(),
    }));

    return (
      <DataTable
        hidePaging
        isLoading={!state.store.isLoaded}
        rows={props.rows}
        columns={columns}
        rowTemplate={(item) => <RowTemplate key={item.id} item={item} />}
      />
    );
  }
);

type IRowTemplateProps = {
  item: IWebsocketBufferModel;
};

const RowTemplate = ({ item }: IRowTemplateProps) => (
  <TableRow>
    <TableCell>{item.name}</TableCell>
    <TableCell>{item.occupancy}</TableCell>
    <TableCell>{item.queue}</TableCell>
    <TableCell>{item.content ? String(item.content) : null}</TableCell>
  </TableRow>
);

class ViewStore extends BaseTableViewStore<
  IServerTransportsModel,
  IClientTransportsModel,
  null,
  Provider
> {
  constructor() {
    super(new Provider());
  }
}

class Provider extends DataTableStore<IServerTransportsModel, null> {
  get endpointUrl(): string {
    return "api/users";
  }
  protected serviceApiUrl = environment.structureServiceApi;
}
