import React from "react";
import { AppAmrProvider } from "./providers/app-provider";
import { AmrRoutes } from "./routing/amr-routes";
import { setAmrTranslation } from "localization/amr-localization";

function App() {
  React.useMemo(() => {
    setAmrTranslation();
  }, []);

  return (
    <AppAmrProvider>
      <AmrRoutes />
    </AppAmrProvider>
  );
}

export default App;
