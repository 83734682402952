import { useEffect, useState } from "react";
import BaseView from "wes_shell_app/base-view";
import { useMapStore } from "../hooks/use-map-store";
import Maplegend from "../components/map-legend";
import MapMap from "../components/map-map";

const MapView = () => {
  const [showComponent, setShowComponent] = useState(false);
  const store = useMapStore();

  useEffect(() => {
    store.openConnection();

    return () => {
      store.closeConnection();
    };
  }, [store]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowComponent(true);
    }, 20);

    return () => clearTimeout(timer);
  }, []);

  return (
    <BaseView heightFull={true}>
      {showComponent && (
        <div className="relative">
          <div className="absolute top right-0 flex gap-4 items-center justify-items-center z-50">
            <Maplegend />
          </div>
          <MapMap />
        </div>
      )}
    </BaseView>
  );
};

export default MapView;
