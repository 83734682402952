import React from "react";
import { observer } from "mobx-react";
import { TableRow, TableCell } from "@mui/material";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { IClientWebsocketRobotUtilizationModel } from "models/client-models";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "robot",
  },
  {
    label: "utilization",
  },
  {
    label: "activeTime",
  },
  {
    label: "totalTime",
  },
];

interface IUtilizationDataTableProps {
  rows: IClientWebsocketRobotUtilizationModel[];
}

export const UtilizationDataTable = observer(
  (props: IUtilizationDataTableProps) => (
    <DataTable
      hidePaging
      rows={props.rows}
      columns={columns}
      rowTemplate={(item) => <RowTemplate key={item.robotId} item={item} />}
    />
  )
);

type IRowTemplateProps = {
  item: IClientWebsocketRobotUtilizationModel;
};

const RowTemplate = ({ item }: IRowTemplateProps) => (
  <TableRow>
    <TableCell>{item.robotName}</TableCell>
    <TableCell>{item.clientRobotUtilizationPercentage}</TableCell>
    <TableCell>{item.clientWorkingTime}</TableCell>
    <TableCell>{item.clientAvailableTime}</TableCell>
  </TableRow>
);
