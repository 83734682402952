import { MobXProviderContext } from 'mobx-react';
import * as React from 'react';
import { AmrRootStore } from '../../stores/amr-root-store';

type IAppMobxContext = {
  rootStore: AmrRootStore;
}

export function useAmrAppMobxContext() {
  return React.useContext<IAppMobxContext>(MobXProviderContext as any);
}