import React from "react";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { TableRow, TableCell } from "@mui/material";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "codes",
  },
];

type ContentDataTableProps = {
  content: string[];
};

export const ContentDataTable: React.FC<ContentDataTableProps> = ({
  content,
}) => {

  return (
    <DataTable
      rows={content}
      hidePaging
      columns={columns}
      rowTemplate={(item) => <RowTemplate key={item} item={item} />}
    />
  );
};

type RowTemplateProps = {
  item: string;
};

const RowTemplate: React.FC<RowTemplateProps> = ({ item }) => {
  return (
    <TableRow>
      <TableCell>{item}</TableCell>
    </TableRow>
  );
};
