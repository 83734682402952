import { Popover } from "wes_shell_app/popover";
import InfoIcon from "@mui/icons-material/Info";
import { useMapStore } from "features/map/hooks/use-map-store";
import { observer } from "mobx-react";
import { getTranslation } from "wes_shell_app/localization-utils";

const Maplegend = observer(() => {
  const store = useMapStore();

  return (
    <Popover
      trigger={<InfoIcon color="primary" />}
      children={
        <div className="p-4">
          {store.images?.content.map((image) => {
            return (
              <div className="flex items-center gap-2 mb-2" key={image.id}>
                <img src={image.icon} alt={image.name} className="w-10 h-10" />
                <span>{getTranslation(`${image.name}${image.state}`)}</span>
              </div>
            );
          })}
        </div>
      }
      transformHorizontal={"right"}
    />
  );
});
export default Maplegend;
