import { runInAction } from "mobx";
import { AmrDashboardViewStore } from "../features/dashboard/stores/amr-dashboard-view-store";
import { AmrTransportViewStore } from "../features/transport/stores/view/amr-transport-view-store";
import { MapViewStore } from "features/map/stores/map-view-store";

export class ViewStore {
  dashboard = new AmrDashboardViewStore();
  transport = new AmrTransportViewStore();
  map = new MapViewStore();
}

export class AmrRootStore {
  readonly view = new ViewStore();
}

export let amrRootStore = new AmrRootStore();

export function initStores() {
  amrRootStore = runInAction(() => new AmrRootStore());
};

export default amrRootStore;