import * as React from 'react';
import { Dialog as MuiDialog, DialogTitle, DialogContent, DialogActions, Divider, ClickAwayListener } from '@mui/material';
import { CloseDialogButton } from './close-dialog-button';
import { ConfirmDialogButton } from './confirm-dialog-button';
import Big from "wes_shell_app/big";
import { makeAutoObservable } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react';
import { IWithChildren } from "wes_shell_app/types";

type IDialogPropsBase = IWithChildren & {
  open?: boolean;
  intlTitle?: string;
}

type IDialogPropsActions = {
  actions: React.ReactNode[];
}

type IDialogActionProps = {
  confirmLabel?: string;
  confirmDisabled?: boolean;
  confirmAction?: () => void;
  confirmActionVariant?: "contained" | "confirm";
  cancelLabel?: string;
  cancelDisabled?: boolean;
  cancelAction?: () => void;
}

type IDialogProps = IDialogPropsBase & (IDialogActionProps | IDialogPropsActions) & {
  provider?: any;
  trigger?: React.ReactElement;
  onClose?: () => void;
};

export const Dialog = observer((props: IDialogProps) => {
  const { actions } = props as IDialogPropsActions;
  const { cancelDisabled, cancelLabel, confirmAction, confirmDisabled, confirmLabel, confirmActionVariant } = props as IDialogActionProps;

  const state = useLocalObservable(() => ({
    store: new DialogStore(),
  }));

  React.useEffect(() => {
    if (props.open) {
      state.store.openDialog();
    } else {
      state.store.closeDialog();
    }
  }, [props.open]);

  return <>
    {props.trigger && React.cloneElement(props.trigger, { onClick: state.store.openDialog })}
    <MuiDialog
      open={state.store.isOpen}
      maxWidth='sm'
      fullWidth
      onClose={() => {
        state.store.closeDialog();
        props.onClose?.();
      }}
    >
      <DialogTitle>
        <Big intlId={props.intlTitle} />
      </DialogTitle>
      <Divider />
      <DialogContent>
        {props.children}
      </DialogContent>
      <Divider />
      <DialogActions>
        {actions}
        <CloseDialogButton
          label={cancelLabel || 'Close'}
          onClick={() => {
            state.store.closeDialog();
            props.onClose?.();
          }}
          disabled={cancelDisabled}
        />
        {confirmAction && <ConfirmDialogButton
          intlId={confirmLabel || 'confirm'}
          onClick={confirmAction}
          disabled={confirmDisabled}
          variant={confirmActionVariant || "contained"}
        />}
      </DialogActions>
    </MuiDialog>
  </>;
});


type TPrivates = '_processDialogAction' | '_openDialog';
type IAction = 'cancel' | 'confirm' | 'open';

class DialogStore {
  constructor() {
    makeAutoObservable<this, TPrivates>(this);
  }

  isOpen: boolean = false;
  openDialog = () => this.isOpen = true;
  closeDialog = () => this.isOpen = false;
}