import { IWebsocketsOldestUnprocessedRequestModel } from "../../../models/server-models";
import { Box, FormLabel } from "@mui/material";
import { KeyboardDoubleArrowRight } from "@mui/icons-material";
import Big from "wes_shell_app/big";
import LocalizedText from "wes_shell_app/localized-text";
import { IWithTranslation } from "wes_shell_app/types";

export type IContentBoxProps = IWithTranslation & {
    intlId?: string;
    oldestMessage: IWebsocketsOldestUnprocessedRequestModel;
  };

export default function KPIUnprocessed({intlId,oldestMessage,}: IContentBoxProps) {

    function calculateMinutesDifference(issueAt) {
        if (!issueAt) return;
        const date = new Date(issueAt);
        const newDate = new Date(
          date.getTime() + date.getTimezoneOffset() * 60 * 1000
        );
        const offset = date.getTimezoneOffset() / 60;
        const hours = date.getHours();
        newDate.setHours(hours - offset);
    
        const now = new Date();
        const timeDiff = now.getTime() - newDate.getTime();
    
        const minutesPassed = Math.floor(timeDiff / (1000 * 60));
        const hoursPassed = Math.floor(minutesPassed / 60);
        const remainingMinutes = minutesPassed % 60;
    
        let result = "";
        if (hoursPassed > 0) {
          result += hoursPassed + "h ";
        }
        result += remainingMinutes + "m";
    
        return result;
      }

    return <Box className="w-full h-[90px]">
        <FormLabel sx={{fontSize:"0.75em"}} >
            <LocalizedText intlId={intlId}/>
        </FormLabel>
        {oldestMessage.id ?
        <div className="grid grid-cols-12 gap-x-3 py-1 w-full">
            <div className="col-span-2">
                <LocalizedText intlId="minutesBack" />
                <Big>{calculateMinutesDifference(oldestMessage.issuedAt)}</Big>
            </div>
            <div className="col-span-2">
                <LocalizedText intlId="requestId" />
                <Big>{oldestMessage.id}</Big>
            </div>
            <div className="col-span-2">
                <LocalizedText intlId="queue" />
                <Big>{oldestMessage.queue}</Big>
            </div>
            <div className="col-span-1">
                <LocalizedText intlId="priority" />
                <Big>{oldestMessage.priority}</Big>
            </div>
            <div className="col-span-4 grid grid-cols-5 items-center">
                <div className="col-span-2">
                <Big>{oldestMessage.sourceWorkplaceName}</Big>
                <Big>{oldestMessage.sourcePositionName}</Big>
                </div>
                <div className="col-span-1">
                <KeyboardDoubleArrowRight />
                </div>
                <div className="col-span-2">
                <Big>{oldestMessage.targetWorkplaceName}</Big>
                <Big>{oldestMessage.targetPositionName}</Big>
                </div>
            </div>
            <div className="col-span-1">
                <LocalizedText intlId="taskId" />
                <Big>{oldestMessage.lastTaskId}</Big>
            </div>
        </div> :
        <div className="w-full h-full">
            <Big intlId="noOldestRequest" />
        </div>
        }
    </Box>

}