import React from "react";
import { observer } from "mobx-react";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { FormLabel } from "@mui/material";
import { useAmrDashboardStore } from "../hooks/use-amr-dashboard-store";
import { AmrDashboardRequestDataProvider } from "../stores/data/amr-dashboard-request-data-provider";
import { updateItem } from "wes_shell_app/api-utils";
import { environment } from "environment";
import Button from "wes_shell_app/button";
import { Box } from "@mui/material";
import { getTranslation } from "wes_shell_app/localization-utils";

type ButtonIconProps = {
  icon: React.ReactElement;
  type: string;
  status: string;
  onClick: () => void;
};

const ButtonIcon = ({ icon, type, status, onClick }: ButtonIconProps) => {
  const isDisabled = !status || status === type;

  return (
    <Button
      icon={icon}
      disabled={isDisabled}
      onClick={onClick}
      sx={{ padding: "5px 0px" }}
    />
  );
};

export const TaskControl = observer(() => {
  const DashboardStore = useAmrDashboardStore();
  const provider = new AmrDashboardRequestDataProvider();

  if (!DashboardStore.isLoaded) return null;

  const status: string = DashboardStore.transportControlStatus;

  const sendData = async (currentStationID: number, type: string) => {
    try {
      await updateItem(
        `${environment.amrServiceApi}ui/${currentStationID}/${type}`
      );
      console.log("Allocation of requirements: data sent successfully");
    } catch (e) {
      console.error("Error updating item:", e);
    }
  };

  return (
    <React.Fragment>
      {status && (
        <FormLabel sx={{ fontSize: "0.75em" }}>
          <Box
            sx={{
              backgroundColor: (() => {
                switch (status) {
                  case "pause":
                    return "#ffcc80";
                  case "resume":
                    return "#a5d6a7";
                  default:
                    return "#ffff";
                }
              })(),
            }}
            className="rounded p-1 text-center"
          >
            <p className="font-bold text-sm">
              {(() => {
                switch (status) {
                  case "pause":
                    return getTranslation("stopped").toUpperCase();
                  case "resume":
                    return getTranslation("running").toUpperCase();
                  default:
                    return getTranslation("").toUpperCase();
                }
              })()}
            </p>
          </Box>
        </FormLabel>
      )}
      <div className="inline-flex flex-row flex-wrap justify-center w-full min-h-[4.5rem] items-center">
        <ButtonIcon
          icon={<PauseCircleOutlineIcon sx={{ fontSize: 45 }} />}
          type="pause"
          status={status}
          onClick={() => sendData(provider.defaultModel.stationId, "pause")}
        />
        <ButtonIcon
          icon={<PlayCircleOutlineIcon sx={{ fontSize: 45 }} />}
          type="resume"
          status={status}
          onClick={() => sendData(provider.defaultModel.stationId, "resume")}
        />
      </div>
    </React.Fragment>
  );
});
