import React from "react";
import { observer } from "mobx-react";
import { TableRow, TableCell } from "@mui/material";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { useAmrDashboardStore } from "../hooks/use-amr-dashboard-store";
import { IWebsocketTableMessagesActiveRobotModelContent } from "models/server-models";
import { ContentDataTable } from "./content-data-table";
import { Dialog } from "../../../common/base-components/dialog/dialog";

const columns: IDataTableHeaderCellProps[] = [];

export const ActiveRobotDataTable: React.FC = observer(() => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [showContent, setShowContent] = React.useState<string[]>([]);

  const store = useAmrDashboardStore();
  if (!store.isLoaded) return null;

  const handleClickOpen = (content: string[]) => {
    setOpenDialog(true);
    setShowContent(content);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <React.Fragment>
      <Dialog
        intlTitle="content"
        open={openDialog}
        onClose={handleClose}
        trigger={
          <DataTable
            rows={store.activeRobotMessagesDataTable}
            hidePaging
            columns={columns}
            rowTemplate={(item) => (
              <RowTemplate
                key={item.requestId}
                item={item}
                handleOpen={handleClickOpen}
              />
            )}
          />
        }
      >
        <ContentDataTable content={showContent} />
      </Dialog>
    </React.Fragment>
  );
});

const truncateString = (str: string | undefined, num: number) => {
  if (!str) {
    return;
  }
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

type RowTemplateProps = {
  item: IWebsocketTableMessagesActiveRobotModelContent;
  handleOpen: (content: string[]) => void;
};

const RowTemplate: React.FC<RowTemplateProps> = ({ item, handleOpen }) => {
  let {
    robotName,
    requestId,
    taskId,
    workplaceFrom,
    positionFrom,
    workplaceTo,
    positionTo,
    content,
  } = item;

  requestId = requestId || "-";
  taskId = taskId || "-";
  content = content || [];

  const transport = workplaceFrom
    ? `${workplaceFrom}/${positionFrom} -> ${workplaceTo}/${positionTo}`
    : "";

  return (
    <TableRow className="cursor-pointer" onClick={() => handleOpen(content)}>
      <TableCell>
        <div>{`${robotName} ${requestId}/${taskId}`}</div>
        <div>{truncateString(transport, 25)}</div>
        <div>{truncateString(content[0], 35)}</div>
      </TableCell>
    </TableRow>
  );
};
