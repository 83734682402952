import React from "react";
import { observer } from "mobx-react";
import { TableRow, TableCell } from '@mui/material';
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { IClientTransportsModel } from "../../../models/client-models";
import { useAmrTransportStore } from "../hooks/use-transports-store";
import { AmrTransportViewStore } from "../stores/view/amr-transport-view-store";
import NumericInputField from "wes_shell_app/numeric-input-field";
import { Delete } from "@mui/icons-material";
import { ITransportState } from "../../../models/server-models";
import DialogConfirmButton from "wes_shell_app/dialog-confirm-button";
import { QueueSelector } from "common/connected-components/queue-selector/queue-selector";
import  { getTranslation } from "wes_shell_app/localization-utils";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: 'id',
  },
  {
    label: 'state',
  },
  {
    label: 'queue',
  },
  {
    label: 'start',
  },
  {
    label: 'target',
  },
  {
    label: 'priority',
  },
  {
    label: 'created',
  },
  {
    label: 'received',
  },
  {
    label: 'task',
  },
  {
    label: 'parent',
  },
  {
    label: 'predecessor',
  },
  {
    label: 'content',
  },
  {
    label: '',
  },
];

export const TransportDataTable = observer(() => {
  const store = useAmrTransportStore();
  if (!store.isLoaded) return null;

  return <DataTable
    page={store.page}
    isLoading={!store.isLoaded}
    onPageChange={store.setPage}
    activeSortProperty={store.sortBy}
    onSortChange={store.setSort}
    onDirectionChange={store.setDirection}
    direction={store.direction}
    pageCount={store.totalCount}
    rows={store.items}
    pageSize={store.pageSize}
    onPageSizeChange={store.setPageSize}
    columns={columns}
    rowTemplate={(item) => <RowTemplate
      key={item.id}
      item={item}
      store={store}
      isAdmin={store.isAdmin}
    />}
  />
});

type IRowTemplateProps = {
  item: IClientTransportsModel;
  store: AmrTransportViewStore;
  isAdmin?: boolean;
}

const RowTemplate = observer((props: IRowTemplateProps) => {
  const { id, state, queue, created, priority, received, lastTaskId, parentId, predecessorId, content } = props.item;
  return <TableRow>
    <TableCell>
      {id}
    </TableCell>
    <TableCell>
      {getTranslation(state+"State")}
    </TableCell>
    <TableCell>
      {state === 'created' && props.isAdmin
        ? <QueueSelector
          size="small"
          selectedQueueId={queue}
          selectQueue={(queue) => {
            props.store.patchLocal(id, { queue });
            props.store.patchItemQueue(id);
          }}
        />
        : queue
      }
    </TableCell>
    <TableCell>
      {props.item.sourceUuids ?
      props.store.getWorkplaceLanesNames(props.item.sourceWorkplaceId, props.item.sourceUuids).map(item => <div>{item}</div>) :
      props.store.getWorkplacePositionNames(props.item.sourceWorkplaceId, props.item.sourcePositionUuid)}
    </TableCell>
    <TableCell>
      {props.item.targetUuids ?
      props.store.getWorkplaceLanesNames(props.item.sourceWorkplaceId, props.item.targetUuids).map(item => <div>{item}</div>) :
      props.store.getWorkplacePositionNames(props.item.targetWorkplaceId, props.item.targetPositionUuid)}
    </TableCell>
    <TableCell width="120px">
      {state === 'created' && props.isAdmin ? <NumericInputField
        size="small"
        useDebounce
        value={props.store.getEditableItemValue(id, 'priority')}
        onChange={(v) => {
          props.store.patchLocal(id, { priority: v });
          props.store.patchItemPriority(id);
        }}
      /> : priority}
    </TableCell>
    <TableCell>
      {created}
    </TableCell>
    <TableCell>
      {received}
    </TableCell>
    <TableCell>
      {lastTaskId}
    </TableCell>
    <TableCell>
      {parentId}
    </TableCell>
    <TableCell>
      {predecessorId}
    </TableCell>
    <TableCell>
      {content && content.map(item => <div>{item}</div>)}
    </TableCell>
    <TableCell>
      {state === ITransportState.created && props.isAdmin && <DialogConfirmButton
        onConfirm={(item) => props.store.deleteTransport(item.id)}
        icon={<Delete color="error" />}
        item={props.item}
      />}
    </TableCell>
  </TableRow>;
});