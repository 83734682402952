const basePath = "/amr-transport";
export enum AmrRoutePaths {
  home = "",
  transport = "/transport",
  warehouseMap = "/map",
}

export function amrMenu() {
  return {
    items: [
      {
        title: "Dashboard",
        link: basePath,
        icon: "Dashboard",
      },
      {
        title: "Transport",
        link: basePath + AmrRoutePaths.transport,
        icon: "LocalShipping",
      },
      {
        title: "map",
        link: basePath + AmrRoutePaths.warehouseMap,
        icon: "Map",
      },
    ],
  };
}
