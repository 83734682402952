import React from "react";
import { observer } from "mobx-react";
import NumericInputField from "wes_shell_app/numeric-input-field";

type IPrioritySelectorProps = {
  from: number;
  to: number;
  onFromChange: (v: number) => void;
  onToChange: (v: number) => void;
}

export const PrioritySelector = observer((props: IPrioritySelectorProps) => {
  return <div className="flex flex-row space-x-2">
    <NumericInputField
      label="priorityFrom"
      value={props.from}
      onChange={props.onFromChange}
      min={0}
    />
    <NumericInputField
      label="priorityTo"
      value={props.to}
      onChange={props.onToChange}
      min={0}
    />
  </div>;
});