import React from "react";
import { observer, useLocalObservable } from "mobx-react";
import {
  Close,
  Apps as BulkIcon,
  Engineering as ManualIcon,
  MyLocation as GroundIcon,
  Blinds as RackIcon
 } from "@mui/icons-material";
import Select from "wes_shell_app/select";
import MultiSelect from "wes_shell_app/multi-select";
import Button from "wes_shell_app/button";
import { TransportTargetSelectorStore } from "./store/transport-target-selector-store";
import { Handover } from "models/server-models";

type ITransportTargetSelectorProps = {
  selectedWorkplaceId?: number;
  selectedPositionId?: string;
  selectedLaneIds?: string[];
  selectWorkplace: (id: number) => void;
  selectPosition: (id: string) => void;
  selectLanes: (ids: string[]) => void;
  clearPosition?: () => void;
  clearWorkplace?: () => void;
  clearLanes?: () => void;
  targetWorkplaceRequired?: boolean;
  sourceWorkplaceId?: number;
  isHandoverSensitive?: boolean;
}

export const TransportTargetSelector = observer(({
  selectedWorkplaceId,
  selectedPositionId,
  selectedLaneIds,
  selectWorkplace,
  selectPosition,
  selectLanes,
  clearPosition,
  clearWorkplace,
  clearLanes,
  targetWorkplaceRequired,
  sourceWorkplaceId,
  isHandoverSensitive
}: ITransportTargetSelectorProps) => {

  const state = useLocalObservable(() => ({
    store: new TransportTargetSelectorStore(),
  }));

  const store = state.store;

  const selectWorkplaceHandler = (id: number) => {
    store.selectWorkplace(id);
    store.clearPosition();
    store.clearLanes();
    selectWorkplace(id);
    clearPosition?.();
    clearLanes?.();
  }
  const selectPositionHandler = (id: string) => {
    store.selectPosition(id);
    selectPosition(id);
  }
  const selectLanesHandler = (ids: string[]) => {
    store.selectLanes(ids);
    selectLanes(ids);
  }

  const clearWorkplaceHandler = () => {
    store.clearWorkplace();
    store.clearPosition();
    store.clearLanes();
    clearWorkplace?.();
    clearPosition?.();
    clearLanes?.();
  }
  const clearPositionHandler = () => {
    store.clearPosition();
    clearPosition?.();
  }
  const clearLanesHandler = () => {
    store.clearLanes();
    clearLanes?.();
  }

  React.useEffect(() => {
    store.selectWorkplace(selectedWorkplaceId);
  }, [selectedWorkplaceId]);
  React.useEffect(() => {
    store.selectPosition(selectedPositionId);
  }, [selectedPositionId]);
  React.useEffect(() => {
    store.selectLanes(selectedLaneIds);
  }, [selectedLaneIds]);

  const workplacesWithIcons = store.workplaces.map((workplace) => {
      const content = <div>
      {workplace.handover === "bulk-zone" ? <BulkIcon/> :
      workplace.handover === "rack" ? <RackIcon/> :
      workplace.handover === "flowrack" ? <RackIcon/> :
      workplace.handover === "conveyor" ? <GroundIcon/> :
      workplace.handover === "manual" ? <ManualIcon/> :
      <GroundIcon/>}
      {workplace.content}
    </div>;
    return {...workplace, content};
  })

  if (!store.isLoaded) return null;

  return <div
  className={ store.selectedWorkplace?.handover === "bulk-zone" && isHandoverSensitive ?
  "flex flex-col justify-between space-y-4" :
  "flex flex-row space-x-2"
  }>
    <Select
      required={targetWorkplaceRequired && store.getWorkplaceHandover(sourceWorkplaceId) !== "bulk-zone"}
      intlId="targetWorkplace"
      value={store.selectedWorkplaceId}
      items={workplacesWithIcons}
      onChange={selectWorkplaceHandler}
      fullWidth
      endAdornment={store.selectedWorkplaceId
        ? <div className="mr-4 flex align-center">
          <Button variant="small-icon" onClick={clearWorkplaceHandler}>
            <Close fontSize="small" color="action" />
          </Button>
        </div>
        : undefined}
    />
    {
      store.selectedWorkplace?.handover === "bulk-zone" && isHandoverSensitive ?
      <MultiSelect
        intlId="targetLanes"
        value={store.selectedLaneIds}
        items={
          store.lanes.filter((pos) => {
            const enteredLanes: number[] = [];
            store.selectedLanes.forEach((x) => enteredLanes.push(x.lane))

            return !enteredLanes?.includes(pos.lane) || store.selectedLaneIds?.includes(`${pos.id}`) ;
          })
        }
        onChange={selectLanesHandler}
        fullWidth
        readOnly={!store.selectedWorkplaceId}
        endAdornment={store.selectedLaneIds?.length > 0
          ? <div className="mr-4 flex align-center">
            <Button variant="small-icon" onClick={clearLanesHandler}>
              <Close fontSize="small" color="action" />
            </Button>
          </div>
          : undefined}
      /> :
      <Select
        intlId="targetPosition"
        value={store.selectedPositionId}
        items={store.positions}
        onChange={selectPositionHandler}
        fullWidth
        readOnly={!store.selectedWorkplaceId}
        endAdornment={store.selectedPositionId
          ? <div className="mr-4 flex align-center">
            <Button variant="small-icon" onClick={clearPositionHandler}>
              <Close fontSize="small" color="action" />
            </Button>
          </div>
          : undefined}
      />
    }
  </div>;
});