import { makeAutoObservable } from "mobx";
import { SelectItemType } from "wes_shell_app/types";
import { StructureDataProvider } from "../../../providers/structure-data-provider";
import { Handover } from "models/server-models";

export class TransportSourceSelectorStore {
  private readonly provider = new StructureDataProvider();

  constructor() {
    makeAutoObservable(this);
  }

  private _selectedWorkplaceId: number;
  private _selectedPositionId: string;
  private _selectedLaneIds: string[];

  selectWorkplace = (id: number) => this._selectedWorkplaceId = id;
  selectPosition = (id: string) => this._selectedPositionId = id;
  selectLanes = (ids: string[]) => this._selectedLaneIds = ids;
  clearWorkplace = () => this._selectedWorkplaceId = null;
  clearPosition = () => this._selectedPositionId = null;
  clearLanes = () => this._selectedLaneIds = null;

  get workplaces(): (SelectItemType & { handover?: Handover })[] {
    return this.provider.workplaces
      .map(x => ({ id: x.id, content: x.name, handover: x.handover }));
  }

  get positions(): SelectItemType[] {
    return this.provider.getWorkplacePositions(this._selectedWorkplaceId)
      .filter(x => x.operation === "out" || x.operation === "inout")
      .map(x => ({ id: x.id, content: x.name }));
  }

  get lanes(): (SelectItemType & { lane?: number, slot?: number })[] {
    return this.provider.getWorkplacePositions(this._selectedWorkplaceId)
      .filter(x => x.operation === "out" || x.operation === "inout")
      .map(x => ({ id: x.id, content: x.name, lane: x.lane, slot: x.slot }));
  }

  get selectedWorkplace(): (SelectItemType & { handover?: Handover }) {
    return this.provider.workplaces
      .map(x => ({ id: x.id, content: x.name, handover: x.handover }))
      .find(x => x.id === this._selectedWorkplaceId);
  }

  get selectedLanes(): (SelectItemType & { lane?: number, slot?: number })[] {
    return this.provider.getWorkplacePositions(this._selectedWorkplaceId)
      .filter(x => x.operation === "out" || x.operation === "inout")
      .filter(x => this._selectedLaneIds?.includes(x.id))
      .map(x => ({ id: x.id, content: x.name, lane: x.lane, slot: x.slot }));
  }

  get selectedWorkplaceId() { return this._selectedWorkplaceId; }
  get selectedPositionId() { return this._selectedPositionId; }
  get selectedLaneIds() { return this._selectedLaneIds; }
}