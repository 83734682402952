import { Box, FormLabel } from "@mui/material";
import Big from "wes_shell_app/big";
import LocalizedText from "wes_shell_app/localized-text";
import { IWithTranslation } from "wes_shell_app/types";

export type IKPIProps = IWithTranslation & {
    icon?: React.ReactNode;
    intlId?: string;
    onClick?: () => void;
    children?: React.ReactNode;
  };

export default function KPI({
    intlId,
    children,
    icon,
    onClick,}: IKPIProps) {
    return <Box onClick={onClick} className="cursor-pointer  flex flex-col justify-center">
        <FormLabel className="text-xs flex justify-center" sx={{fontSize:"0.75em"}} >
            <LocalizedText intlId={intlId}/>
        </FormLabel>
        <div className="mt-2 flex space-x-3 justify-center">
            {icon}
            <Big>{children}</Big>
        </div>
    </Box>
}