import cs from "./cs.json";
import en from "./en.json";
import {
  getApplicationLocalization,
  setTranslations,
} from "wes_shell_app/localization-utils";

export function setAmrTranslation() {
  const locale = getApplicationLocalization();
  const file = getFilePath(locale);
  setTranslations(locale, file);
}

function getFilePath(locale: string) {
  switch (locale) {
    case "en":
      return en;
    default:
      return cs;
  }
}
