import { observer } from "mobx-react";
import React from "react";
import { IModelDialogProps } from "wes_shell_app/model-dialog";
import { TransportSourceSelector } from "common/connected-components/transport-source-selector/transport-source-selector";
import { TransportTargetSelector } from "common/connected-components/transport-target-selector/transport-target-selector";
import { IClientCreateTransportRequestModel } from "models/client-models";
import { QueueSelector } from "../../../common/connected-components/queue-selector/queue-selector";
import NumericInputField from "wes_shell_app/numeric-input-field";
import TextInputField from "wes_shell_app/text-input-field";

export const TransportDialogForm = observer((props: IModelDialogProps<IClientCreateTransportRequestModel>) => {
  const { item, patchItem } = props;

  return <div className="flex flex-col justify-between space-y-4">
    <TransportSourceSelector
      selectedPositionId={item?.sourcePositionUuid}
      selectPosition={(v) => patchItem({ sourcePositionUuid: v })}
      clearPosition={() => patchItem({ sourcePositionUuid: null })}
      selectedWorkplaceId={item?.sourceWorkplaceId}
      selectWorkplace={(v) => patchItem({ sourceWorkplaceId: v })}
      clearWorkplace={() => patchItem({ sourceWorkplaceId: null })}
      selectedLaneIds={item?.sourceUuids}
      selectLanes={(v) => {
        patchItem({ sourceUuids: null });
        patchItem({ sourceUuids: v });
      }}
      clearLanes={() => patchItem({ sourceUuids: null })}
      isRequired
      isHandoverSensitive
    />
    <TransportTargetSelector
      selectedPositionId={item?.targetPositionUuid}
      selectPosition={(v) => patchItem({ targetPositionUuid: v })}
      clearPosition={() => patchItem({ targetPositionUuid: null })}
      selectedWorkplaceId={item?.targetWorkplaceId}
      selectWorkplace={(v) => patchItem({ targetWorkplaceId: v })}
      clearWorkplace={() => patchItem({ targetWorkplaceId: null })}
      selectedLaneIds={item?.targetUuids}
      selectLanes={(v) => {
        patchItem({ targetUuids: null });
        patchItem({ targetUuids: v });
      }}
      clearLanes={() => patchItem({ targetUuids: null })}
      targetWorkplaceRequired
      sourceWorkplaceId={item?.sourceWorkplaceId}
      isHandoverSensitive
    />
    <QueueSelector
      required
      selectedQueueId={item?.queue}
      selectQueue={(v) => patchItem({ queue: v })}
      clearQueue={() => patchItem({ queue: null })}
    />
    <NumericInputField
      required
      label="priority"
      value={item?.priority}
      min={0}
      onChange={(v) => patchItem({ priority: v })}
    />
    <TextInputField
      value={item?.content?.join()}
      placeholder="content"
      label="content"
      onChange={(v) => {
        patchItem({ content: null });
        patchItem({ content: v?.split(",") });
      }}
      />
  </div>;
});