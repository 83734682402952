import { observer } from "mobx-react";
import React from "react";
import BaseView from "wes_shell_app/base-view";
import { TransportDataTable } from "../components/transport-data-table";
import { TransportDataTableFilter } from "../components/transport-data-table-filter";
import { TransportModelDialogForm } from "../components/transport-model-dialog-form";

export const TransportView = observer(() => {
  return (
    <BaseView>
      <TransportDataTableFilter actionContent={
        <TransportModelDialogForm dialogTitleIntl="newRequest" />
        }/>
      <TransportDataTable />
    </BaseView>
  );
});