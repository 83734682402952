import React from "react";
import { observer, useLocalObservable } from "mobx-react";
import { Close } from "@mui/icons-material";
import Select from "wes_shell_app/select";
import Button from "wes_shell_app/button";
import { QueueSelectorStore } from "./store/queue-selector-store";

type IQueueSelectorProps = {
  selectedQueueId?: string;
  selectQueue: (id: string) => void;
  clearQueue?: () => void;
  required?: boolean;
  size?: 'small';
}

export const QueueSelector = observer(({
  selectedQueueId,
  selectQueue,
  clearQueue,
  required,
  size
}: IQueueSelectorProps) => {

  const state = useLocalObservable(() => ({
    store: new QueueSelectorStore(),
  }));

  const store = state.store;

  const selectQueueHandler = (id: string) => selectQueue(id);

  const clearQueueHandler = () => {
    store.clearQueue();
    clearQueue?.();
  }
  //@ts-ignore
  React.useEffect(() => {
    store.selectQueue(selectedQueueId);
  }, [store, store.selectQueue, selectedQueueId]);

  if (!state.store.isLoaded) return null;

  return <>
    <Select
      intlId={size === 'small' ? undefined : "queue"}
      size={size}
      required={required}
      value={store.selectedQueueId}
      items={store.items}
      onChange={selectQueueHandler}
      fullWidth
      endAdornment={(store.selectedQueueId && clearQueue)
        ? <div className="mr-4 flex align-center">
          <Button variant="small-icon" onClick={clearQueueHandler}>
            <Close fontSize="small" color="action" />
          </Button>
        </div>
        : undefined}
    />
  </>;
});