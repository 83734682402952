import { makeAutoObservable } from "mobx";
import { serverToClientQueueModel } from "models/mappers";
import { SelectItemType } from "wes_shell_app/types";
import { QueueDataStore } from "./queue-data-store";

export class QueueSelectorStore {
  private readonly provider = new QueueDataStore();

  constructor() {
    makeAutoObservable(this);
  }

  private _selectedQueueId: string;

  selectQueue = (id: string) => this._selectedQueueId = id;
  clearQueue = () => this._selectedQueueId = null;

  get items(): SelectItemType[] {
    return this.provider.items
      .map(item => serverToClientQueueModel(item))
      .map((x, index) => ({ id: x.name, content: x.name }));
  }

  get selectedQueueId() { return this._selectedQueueId; }
  get isLoaded() { return this.provider.isLoaded; }
}