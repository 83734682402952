import React from "react";
import { Routes, Route } from "react-router-dom";
import routerStore from "wes_shell_app/router-store";
import { DashboardView } from "../features/dashboard/views/dashboard-view";
import { TransportView } from "../features/transport/views/transport-view";
import { useNavigationStore } from "wes_shell_app/use-navigation-store";
import { AmrRoutePaths, amrMenu } from "./amr-menu";
import { observer } from "mobx-react";
import MapView from "features/map/views/map-view";

export const AmrRoutes = observer(() => {
  const navStore = useNavigationStore();

  React.useEffect(() => {
    routerStore.addPaths([...Object.values(AmrRoutePaths)]);
    navStore.setItems(amrMenu().items);
  }, []);

  return (
    <Routes>
      <Route path={AmrRoutePaths.home} element={<DashboardView />} />
      <Route path={AmrRoutePaths.transport} element={<TransportView />} />
      <Route path={AmrRoutePaths.warehouseMap} element={<MapView />} />
    </Routes>
  );
});
