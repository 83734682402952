import React from "react";
import { observer } from "mobx-react";
import { TableRow, TableCell, Collapse } from "@mui/material";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { useAmrDashboardStore } from "../hooks/use-amr-dashboard-store";
import { IWebsocketTableMessagesClientModelContent } from "../../../models/client-models";
import { ConfirmDialog } from "wes_shell_app/confirm-dialog";
import useConfirmDialog from "wes_shell_app/use-confirm-dialog";
import { useFatalLogstore } from "wes_shell_app/use-fatal-log-store";
import Big from "wes_shell_app/big";
import {
  PriorityHigh,
  Check,
  ExpandMore,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { SimpleCard } from "wes_shell_app/simpleCard";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "time",
  },
  {
    label: "severity",
  },
  {
    label: "message",
  },
  {
    label: "confirmed",
  },
  {
    label: "module",
  },
];

export const FatalDataTable = observer(() => {
  const wsStore = useAmrDashboardStore();
  const apiStore = useFatalLogstore();
  const confirmDialog = useConfirmDialog();

  const [moreInfo, setMoreInfo] = React.useState(false);
  const moreInfoAvailable = wsStore.fatalMessagesCount > 1;
  React.useEffect(() => {
    if (moreInfo) apiStore.forceReload();
  }, [moreInfo]);

  if (!wsStore.isLoaded) return null;

  return wsStore.fatalMessagesCount > 0 ? (
    <SimpleCard backgroundColor="#ffcdd2">
      <div
        className={
          moreInfoAvailable
            ? "flex justify-between cursor-pointer"
            : "flex justify-between"
        }
        onClick={moreInfoAvailable ? () => setMoreInfo(!moreInfo) : () => {}}
      >
        <Big
          intlId="fatalTableMessagesLabel"
          suffix={wsStore.fatalMessagesCount.toString()}
        />
        {moreInfoAvailable &&
          (moreInfo ? (
            <KeyboardArrowUp fontSize="large" />
          ) : (
            <ExpandMore fontSize="large" />
          ))}
      </div>
      <Collapse in={moreInfo} collapsedSize={80} timeout="auto">
        <ConfirmDialog
          onConfirm={() => apiStore.confirmMessage(confirmDialog.item)}
        />
        <DataTable
          isLoading={!apiStore.isLoaded}
          page={apiStore.page}
          onPageChange={apiStore.setPage}
          onDirectionChange={apiStore.setDirection}
          direction={apiStore.direction}
          pageCount={apiStore.totalCount}
          pageSize={apiStore.pageSize}
          onPageSizeChange={apiStore.setPageSize}
          rows={moreInfo ? apiStore.items : wsStore.fatalMessagesDataTable}
          hidePaging
          columns={columns}
          rowTemplate={(item) => (
            <RowTemplate
              key={item.id}
              item={item}
              onConfirm={confirmDialog.open}
            />
          )}
        />
      </Collapse>
    </SimpleCard>
  ) : null;
});

type IRowTemplateProps = {
  item: IWebsocketTableMessagesClientModelContent;
  onConfirm: Function;
};

const RowTemplate = (props: IRowTemplateProps) => {
  const { issued, severity, message, appmoduleName, confirmed } = props.item;
  const confirmable = severity === "FATAL" && !confirmed;
  return (
    <TableRow
      className={confirmable ? "cursor-pointer" : ""}
      onClick={() => confirmable && props.onConfirm(props.item)}
      style={{ backgroundColor: "inherit" }}
    >
      <TableCell>{issued}</TableCell>
      <TableCell>{severity}</TableCell>
      <TableCell>
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </TableCell>
      <TableCell>
        {confirmed ? <Check color="success" /> : <PriorityHigh color="error" />}
      </TableCell>
      <TableCell>{appmoduleName}</TableCell>
    </TableRow>
  );
};
