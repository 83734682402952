import React from "react";
import { observer, useLocalObservable } from "mobx-react";
import { TableRow, TableCell } from "@mui/material";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import DataTableStore from "wes_shell_app/data-table-store";
import { environment } from "environment";
import BaseTableViewStore from "wes_shell_app/base-table-view-store";
import { IServerRobotInUseModel } from "models/server-models";
import { IClientRobotInUseModel } from "models/client-models";
import {
  Battery2Bar,
  Battery3Bar,
  Battery4Bar,
  Battery5Bar,
  Battery6Bar,
  BatteryAlert,
} from "@mui/icons-material";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "name",
  },
  {
    label: "state",
  },
  {
    label: "battery",
  },
];

type ITotalRobotsInUseDataTableProps = {
  rows: IClientRobotInUseModel[];
}

export const InUseRobotDataTable = observer(
  ({ rows }: ITotalRobotsInUseDataTableProps) => {
    const state = useLocalObservable(() => ({
      store: new ViewStore(),
    }));

    return (
      <DataTable
        hidePaging
        isLoading={!state.store.isLoaded}
        rows={rows}
        columns={columns}
        rowTemplate={(item) => <RowTemplate key={item.id} item={item} />}
      />
    );
  }
);

type IRowTemplateProps = {
  item: IClientRobotInUseModel;
};

const RowTemplate = ({ item }: IRowTemplateProps) => (
  <TableRow>
    <TableCell>{item.name}</TableCell>
    <TableCell>{item.status}</TableCell>
    <TableCell>{batteryIcon(item.batteryLevel)} {item.batteryLevel}%</TableCell>
  </TableRow>
);

class ViewStore extends BaseTableViewStore<
  IServerRobotInUseModel,
  IClientRobotInUseModel,
  null,
  Provider
> {
  constructor() {
    super(new Provider());
  }
}

class Provider extends DataTableStore<IServerRobotInUseModel, null> {
  get endpointUrl(): string {
    return "api/users";
  }
  protected serviceApiUrl = environment.structureServiceApi;
}

function batteryIcon(level: number) {
  if(!level || level > 100 || level < 0) return <BatteryAlert color="error" style={{ transform: "rotate(90deg)" }}/>
  if(level > 80) return <Battery6Bar  color="success" style={{ transform: "rotate(90deg)" }}/>;
  if(level > 60) return <Battery5Bar  color="success" style={{ transform: "rotate(90deg)" }}/>;
  if(level > 40) return <Battery4Bar  color="warning" style={{ transform: "rotate(90deg)" }}/>;
  if(level > 20) return <Battery3Bar  color="warning" style={{ transform: "rotate(90deg)" }}/>;
  return <Battery2Bar color="error" style={{ transform: "rotate(90deg)" }}/>;
}
