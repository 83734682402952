import { Close } from '@mui/icons-material';
import { isUndefined } from 'lodash';
import Button from 'wes_shell_app/button';
import Select from "wes_shell_app/select";
import { SelectItemType } from "wes_shell_app/types";

const items: SelectItemType[] = [
  {
    contentIntl: 'createdState',
    id: 'created'
  },
  {
    contentIntl: 'runningState',
    id: 'running'
  },
  {
    contentIntl: 'cancelledState',
    id: 'cancelled'
  },
  {
    contentIntl: 'completedState',
    id: 'completed'
  },
  {
    contentIntl: 'deletedState',
    id: 'deleted'
  },
  {
    contentIntl: 'error',
    id: 'error'
  },
 
];

type IActiveFilterSelectorProps = {
  value: string;
  onChange: (v: string) => void;
  onClear: () => void;
}

export const StateSelector = ({ value, onChange, onClear }: IActiveFilterSelectorProps) => {
  const onChangeHandler = (v: string) => onChange(v);

  return <Select
    fullWidth
    intlId="stateSelector"
    placeholder="stateSelector"
    items={items}
    value={!isUndefined(value) ? `${value}` : undefined}
    onChange={onChangeHandler}
    endAdornment={!isUndefined(value)
      ? <div className="mr-4 flex align-center">
        <Button variant="small-icon" onClick={onClear}>
          <Close fontSize="small" color="action" />
        </Button>
      </div>
      : undefined}
  />;
}