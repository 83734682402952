import { environment } from 'environment';
import SingleDataStoreProvider from 'wes_shell_app/single-data-store-provider';
import { IServerCreateTransportRequestModel } from 'models/server-models';
import SharedContextStore from 'wes_shell_app/shared-context-store';

export class AmrDashboardRequestDataProvider extends SingleDataStoreProvider<IServerCreateTransportRequestModel> {
    get endpointUrl(): string { return 'ui'; }
    get patchEndpoint(): string { return `${this.serviceApiUrl}${this.endpointUrl}`; }

    protected serviceApiUrl = environment.amrServiceApi;

    get defaultModel() {
        const store = new SharedContextStore();
        return {
            stationId: store.appContext.currentStationId,
        }
    }
}