import React from "react";
import { Box } from "@mui/material";
import { observer } from "mobx-react";
import DateTimePicker from "wes_shell_app/date-time-picker";
import { StateSelector } from "common/common-components/selectors/state-selector/state-selector";
import { TransportSourceSelector } from "common/connected-components/transport-source-selector/transport-source-selector";
import { TransportTargetSelector } from "common/connected-components/transport-target-selector/transport-target-selector";
import { QueueSelector } from "common/connected-components/queue-selector/queue-selector";
import { PrioritySelector } from "common/common-components/selectors/priority-selector/priority-selector";
import { useAmrTransportStore } from "../hooks/use-transports-store";

type IDataTableFilterProps = {
  actionContent?: React.ReactElement
}

export const TransportDataTableFilter = observer(({actionContent}: IDataTableFilterProps) => {
  const store = useAmrTransportStore();

  return <Box className="flex flex-row justify-between space-x-2 w-full mt-2 mb-8">
    <div className="flex flex-row w-full">
      <div className="flex flex-col space-y-4 w-full">
        <DateTimePicker
          intlId="timeFrom"
          value={store.getFilterValue('timeFrom')}
          onConfirm={(v) => store.setDateFilterValue('timeFrom', v)}
          defaultValue={store.defaultTimeFromValue}
        />
        <DateTimePicker
          intlId="timeTo"
          value={store.getFilterValue('timeTo')}
          onConfirm={(v) => store.setDateFilterValue('timeTo', v)}
        />
        <StateSelector
          value={store.getFilterValue('state')}
          onChange={(v) => store.setFilter('state', v)}
          onClear={() => store.clearFilter('state')}
        />
      </div>
    </div>
    <div className="flex flex-row w-full">
      <div className="flex flex-col space-y-4 w-full">
        <TransportSourceSelector
          selectPosition={(id) => store.setFilter('sourcePosition', id)}
          selectWorkplace={(id) => store.setFilter('sourceWorkplace', id)}
          selectLanes={null}
          selectedPositionId={store.getFilterValue('sourcePosition')}
          selectedWorkplaceId={store.getFilterValue('sourceWorkplace')}
          clearPosition={() => store.clearFilter('sourcePosition')}
          clearWorkplace={() => store.clearFilter('sourceWorkplace')}
        />
        <TransportTargetSelector
          selectPosition={(id) => store.setFilter('targetPosition', id)}
          selectWorkplace={(id) => store.setFilter('targetWorkplace', id)}
          selectLanes={null}
          selectedPositionId={store.getFilterValue('targetPosition')}
          selectedWorkplaceId={store.getFilterValue('targetWorkplace')}
          clearPosition={() => store.clearFilter('targetPosition')}
          clearWorkplace={() => store.clearFilter('targetWorkplace')}
        />
      </div>
    </div>
    <div className="flex flex-row w-full">
      <div className="flex flex-col space-y-4 w-full">
        <QueueSelector
          selectedQueueId={store.getFilterValue('queue')}
          selectQueue={(id) => store.setFilter('queue', id)}
          clearQueue={() => store.clearFilter('queue')}
        />
        <PrioritySelector
          from={store.getFilterValue('priorityFrom')}
          to={store.getFilterValue('priorityTo')}
          onToChange={(v) => store.setFilter('priorityTo', v)}
          onFromChange={(v) => store.setFilter('priorityFrom', v)}
        />
        <div style={{marginLeft: "auto"}}>
        {actionContent}
        </div>
      </div>
    </div>
  </Box>;
})