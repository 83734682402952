import React from "react";
import { IClientRequestTransportModel } from "models/client-models";
import { IServerCreateTransportRequestModel } from "models/server-models";
import ModelDialog, { IWithModalDialog } from "wes_shell_app/model-dialog";
import { useAmrTransportStore } from "../hooks/use-transports-store";
import { AmrTrasportRequestDataProvider } from "../stores/data/amr-transport-request-data-provider";
import { TransportDialogForm } from "./transport-dialog-form";
import { Dialog } from "common/base-components/dialog/dialog";
import { ErrorModel } from "wes_shell_app/api-types";
import { observer } from "mobx-react";

type ITransportDialogFormProps = IWithModalDialog<IClientRequestTransportModel>;
const OVERRIDE_REQUEST_CODE = 109;

export const TransportModelDialogForm = observer(
  (props: ITransportDialogFormProps) => {
    const [isOverrideDialogVisible, setOverrideDialogVisibility] =
      React.useState(false);
    const [_patchedItem, setPatchedItem] =
      React.useState<IServerCreateTransportRequestModel>();
    const store = useAmrTransportStore();

    const provider = new AmrTrasportRequestDataProvider();

    if (!store.isAdmin) return null;

    const onOverrideConfirm = async () => {
      await provider.createItem({ ..._patchedItem, skipOccupancyCheck: true });
      setOverrideDialogVisibility(false);
      dispatchEvent(new CustomEvent("closeModelDialog"));
    };

    //TODO: fix translation
    return (
      <>
        <Dialog
          open={isOverrideDialogVisible}
          intlTitle="confirm"
          cancelAction={() => setOverrideDialogVisibility(false)}
          confirmAction={onOverrideConfirm}
          onClose={() => setOverrideDialogVisibility(false)}
        >
          Chystáte se ignorovat obsazenou pozici. Chcete pokračovat?
        </Dialog>
        <ModelDialog
          intlId="newRequest"
          onSuccess={store.forceReload}
          onError={(
            item: IServerCreateTransportRequestModel,
            errorModel: ErrorModel
          ) => {
            if (errorModel.errno === OVERRIDE_REQUEST_CODE) {
              setPatchedItem(item);
              setOverrideDialogVisibility(true);
            }
          }}
          dialogTitleIntl={props.dialogTitleIntl}
          type={props.type}
          defaultValue={
            provider.defaultModel as IServerCreateTransportRequestModel
          }
          provider={provider}
        >
          <TransportDialogForm />
        </ModelDialog>
      </>
    );
  }
);
