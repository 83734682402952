import * as serverModels from "./server-models";
import * as clientModels from "./client-models";
import formatters from "wes_shell_app/localization-utils";
import _ from "lodash";

export const serverToClientTransportsModel = (
  item: serverModels.IServerTransportsModel
): clientModels.IClientTransportsModel => ({
  ...item,
  created: formatters.formatDateTime(item.created),
  received: formatters.formatDateTime(item.received),
});

export const serverToClientTransportRequestModel = (
  item: serverModels.IServerRequestTransportModel
): clientModels.IClientRequestTransportModel => ({
  ...item,
  created: formatters.formatDateTime(item.created),
  received: formatters.formatDateTime(item.received),
});

export const serverToClientQueueModel = (
  item: serverModels.IServerQueueModel
): clientModels.IClientQueueModel => ({ ...item });

export const serverToClientRobotUtilizationModel = (
  item: serverModels.IWebsocketRobotUtilizationModel
): clientModels.IClientWebsocketRobotUtilizationModel => ({
  ...item,
  clientRobotUtilizationPercentage: _.isNumber(item.robotUtilization)
    ? `${parseFloat(item.robotUtilization.toFixed(1))} %`
    : "--",
  clientAvailableTime: formatters.formatDurationBySeconds(item.availableTime),
  clientWorkingTime: formatters.formatDurationBySeconds(item.workingTime),
});

export const serverToClientRobotModel = (
  item: serverModels.IWebsocketRobotModel
): clientModels.IClientWebsocketRobotModel => ({
  ...item,
  clientRobotUtilizationPercentage: `${parseFloat(
    item.robotUtilization.toFixed(1)
  )} %`,
});

export const serverToClientRequestModel = (
  item: serverModels.IWebsocketRequestModel
): clientModels.IClientWebsocketRequestModel => ({
  ...item,
  clientTransports: `${item.completedTransports} / ${item.totalTransports}`,
});

export const serverToClientTableMessageContent = (
  item: serverModels.IWebsocketTableMessagesServerModelContent,
  clientModule: string
): clientModels.IWebsocketTableMessagesClientModelContent => ({
  ...item,
  issued: formatters.formatDateTime(item.issued),
  appmoduleName: clientModule || "--",
});

export const serverToClientFatalTableMessageContent = (
  item: serverModels.IWebsocketTableMessagesServerModelContent,
  clientModule: string
): clientModels.IWebsocketFatalTableMessagesClientModelContent => ({
  ...item,
  issued: formatters.formatDateTime(item.issued),
  appmoduleName: clientModule || "--",
  warehouseId: 0,
  warehouseName: "",
  stationId: 0,
  stationName: "",
  applicationUuid: "",
  applicationName: "",
  type: "",
});

export const serverToClientBuffersMessageModel = (
  item: serverModels.IWebsocketBufferMessageModel
): clientModels.IBufferMessageModel => ({
  ...item,
  buffersFullnessText: `${item.fullBuffers} / ${item.totalBuffers}`,
  bufferFullnessPerc: Math.round(item.fullBuffers / item.totalBuffers),
});
